import { fetchFromBackend } from "./makeBackendCall";

/**
 * Tries to exchange a temporary token which is the result of the "Login with Slack process".
 * Slack gives us a temptoken which the backend can use to exchange for a real one while validating the authentication.
 * Returns some info about the slack organization.
 */

export async function verifySlackUserAccess(temptoken: any) {
  if (!temptoken) {
    //temptoken not present in the URL. This means that the user needs to trigger the slack authflow. Once that is completed we have the temptoken and can continue here
    throw new Error("Login required");
  }
  //replace state (hide temptoken and make URL look neat)
  window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);
  const response = await fetch("/token", {
    headers: {
      "Content-Type": "application/json",
      temptoken: temptoken,
    },
    method: "POST",
  });
  if (!response.ok) {
    throw new Error("Slack user not authenticated");
  }
  const responseJson = await response.json();
  if (responseJson.error) {
    throw new Error(responseJson.error);
  }
  return responseJson;
}

//get user information from slack
export async function getSlackuserInfo() {
  const [data] = await fetchFromBackend("slack/userInfo");
  const userJson = {
    displayName: data.name,
    userPicture: data.picture,
    tid: data["https://slack.com/team_id"],
    id: data.sub,
    teamName: data["https://slack.com/team_name"],
  };
  return userJson;
}
