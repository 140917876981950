import React, { useEffect, useState } from "react";
import { Form, Flex, FormInput, Tooltip, Alert, ErrorIcon, Button, ExclamationTriangleIcon, Text } from "@fluentui/react-northstar";
import { LanguagePicker } from "./LanguagePicker";
import { useTranslation } from "react-i18next";
import { useCompanyData } from "../../utils/context/companyContext";
import { ICompany, OrgLanguages } from "../../utils/types/company";
import { fetchFromBackend } from "../lib/makeBackendCall";

function CompanyConfigurations({
  setLoading,
  updateSuccess,
  updateError,
  initialConfiguration,
  showModal,
}: {
  setLoading: any;
  updateSuccess?: (message: string) => void;
  updateError?: (message: string) => void;
  initialConfiguration?: boolean;
  showModal?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation(["dashminconfig"]);
  const { companyData, setCompanyData } = useCompanyData();
  const [formData, setFormData] = useState<Partial<ICompany | null | undefined>>(companyData);
  const [initialData, setInitialData] = useState<Partial<ICompany | null | undefined>>(companyData);
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (companyData) {
      // set formData and initialData to track changes
      const relevantFields = pickRelevantFields(companyData);
      setFormData(relevantFields);
      setInitialData(relevantFields);
    }
  }, [companyData]);

  // pick only form-relevant fields from companyData
  const pickRelevantFields = (data: ICompany): Partial<ICompany> => {
    const { companyName, logoUrl, employeeAmount, defaultLanguage, orgLanguages } = data;
    return { companyName, logoUrl, employeeAmount, defaultLanguage, orgLanguages };
  };

  // track changes in the form by comparing formData with initialData
  const isFormChanged = (data: Partial<ICompany | null | undefined>) => {
    return JSON.stringify(data) !== JSON.stringify(initialData);
  };

  useEffect(() => {
    // if defaultLanguage is removed from orgLanguages array, update defaultLanguage value
    const isDefaultLangValid = formData?.orgLanguages?.some((lang) => lang.languageCode === formData.defaultLanguage);

    if (!isDefaultLangValid && formData?.defaultLanguage) {
      setFormData((prev) => ({
        ...prev,
        defaultLanguage: "",
      }));
    }
  }, [formData?.orgLanguages]);

  useEffect(() => {
    setHasChanges(isFormChanged(formData));
    validateForm();
  }, [formData]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    // if user removes defaultLanguage from orgLanguages array, show error
    const isDefaultLangValid = formData?.orgLanguages?.some((lang) => lang.languageCode === formData?.defaultLanguage);
    if (!isDefaultLangValid) {
      newErrors.defaultLanguage = t("Configure.defaultLanguageAlert");
    }

    if (!formData?.employeeAmount || formData.employeeAmount <= 0) {
      newErrors.employeeAmount = t("Configure.employeeError");
    }

    if (!formData?.orgLanguages?.length) {
      newErrors.orgLanguages = t("Configure.noSupportedLanguageAlert");
    }

    if (!formData?.companyName) {
      newErrors.companyName = t("Configure.emptyOrgNameError");
    }

    setErrors(newErrors);
  };

  const handleChange = (field: keyof ICompany, value: string | number | OrgLanguages[]) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const updateDefaultLanguage = (value: string) => {
    handleChange("defaultLanguage", value);
  };

  const updateOrgLanguages = (value: string[]) => {
    const newLanguagesArray = value
      .map((code) => companyData?.supportedLanguages?.find((lang) => lang.languageCode === code))
      .filter((lang): lang is OrgLanguages => !!lang);
    handleChange("orgLanguages", newLanguagesArray);
  };

  async function updateCompanyData() {
    setLoading(true);
    if (companyData) {
      const formOrgLanguages = formData?.orgLanguages?.map((lang) => lang.languageCode);

      const newCompanyData = {
        ...companyData,
        companyName: formData?.companyName ?? "",
        logoUrl: formData?.logoUrl ?? "",
        defaultLanguage: formData?.defaultLanguage ?? "",
        employeeAmount: formData?.employeeAmount ?? 0,
        orgLanguages: formOrgLanguages,
      };

      try {
        await fetchFromBackend("company", "PUT", newCompanyData);
        const [data, responseCode] = await fetchFromBackend("company");
        if (responseCode === 200) {
          setCompanyData(data);
          updateSuccess && updateSuccess("Organization settings was updated successfully");
        } else {
          updateError && updateError("There was an error getting updated organization settings");
        }
      } catch (err) {
        console.error("Failed to update organization settings");
      }
    }
    setLoading(false);
  }

  return (
    <Form>
      <Flex column gap="gap.medium">
        <FormInput
          inverted
          label={t("Configure.orgName")}
          name="organisationName"
          id="organisation-name"
          fluid
          value={formData?.companyName}
          required
          showSuccessIndicator={false}
          error={!!errors.companyName}
          errorMessage={errors.companyName}
          aria-invalid={!!errors.companyName}
          onChange={(_, data) => handleChange("companyName", data?.value ?? "")}
        />
        <FormInput
          inverted
          label={t("Configure.logoURL")}
          name="logoUrl"
          id="logo-Url"
          fluid
          showSuccessIndicator={false}
          value={formData?.logoUrl}
          onChange={(_, data) => handleChange("logoUrl", data?.value ?? "")}
        />
        <FormInput
          type="number"
          inverted
          label={t("Configure.employees")}
          name="employees"
          id="employees"
          value={formData?.employeeAmount}
          required
          error={!!errors.employeeAmount}
          errorMessage={errors.employeeAmount}
          aria-invalid={!!errors.employeeAmount}
          showSuccessIndicator={!errors.employeeAmount}
          onChange={(_, data) => handleChange("employeeAmount", data?.value || 0)}
        />
        <Flex column>
          <Tooltip trigger={<Text styles={{ marginBottom: "3px" }} content={t("Configure.orgLang")} />} content={t("Configure.orgLangTooltip")} />
          <LanguagePicker
            companyData={companyData}
            mode={"SetOrgLanguages"}
            defaultSelected={
              formData?.orgLanguages?.length ? formData?.orgLanguages?.map((lang) => lang.languageCode) : [formData?.defaultLanguage ?? ""]
            }
            handleLanguageArrayChange={updateOrgLanguages}
            inverted
            fluid
          />
          {errors.orgLanguages && <Alert icon={<ErrorIcon />} danger content={errors.orgLanguages} styles={{ marginTop: "8px" }} />}
        </Flex>
        <Flex column>
          <Tooltip trigger={<Text styles={{ marginBottom: "4px" }} content={t("Configure.defLang")} />} content={t("Configure.defLangTooltip")} />
          <LanguagePicker
            companyData={formData}
            disabled={!formData?.orgLanguages || formData?.orgLanguages.map((lang) => lang.languageCode).length === 0}
            defaultSelected={formData?.defaultLanguage}
            handleLanguageChange={updateDefaultLanguage}
            inverted
          />
          {errors.defaultLanguage && <Alert icon={<ErrorIcon />} danger content={errors.defaultLanguage} styles={{ marginTop: "8px" }} />}
        </Flex>
      </Flex>
      {initialConfiguration ? (
        <Flex gap="gap.medium" styles={{ width: "80%" }}>
          <Button
            fluid
            primary
            content="Submit"
            onClick={() => {
              updateCompanyData();
              showModal && showModal(false);
              window.location.reload();
            }}
            disabled={!hasChanges || Object.keys(errors).length > 0}
          />
          <Button
            fluid
            secondary
            content="I'll do it later"
            onClick={() => {
              showModal && showModal(false);
              window.location.reload();
            }}
          />
        </Flex>
      ) : (
        <Flex column gap="gap.smaller">
          <Button
            fluid
            primary
            disabled={!hasChanges || Object.keys(errors).length > 0}
            content={t("Configure.updateSettings")}
            onClick={() => updateCompanyData()}
          />
          {hasChanges && Object.keys(errors).length === 0 && (
            <Alert warning icon={<ExclamationTriangleIcon />} content={t("common.unsavedChanges")} />
          )}
        </Flex>
      )}
    </Form>
  );
}

export default CompanyConfigurations;
