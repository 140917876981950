import React, { useState, useEffect, useRef } from "react";
import { Flex, Text, Loader, Dialog } from "@fluentui/react-northstar";
import { fetchFromBackend } from "../lib/makeBackendCall";
import { customLogout } from "../lib/authHelpers";
import { useCompanyData } from "../../utils/context/companyContext";
import CompanyConfigurations from "../elements/CompanyConfigurations";
import { EnterLicenseCode, InCorrectWorkSpace, ModalHeader } from "./ModalComponents";

export function EnterLicense({ tenant, teamName, queryTeamId }: { tenant: string; teamName: string; queryTeamId: string | undefined }) {
  const { setCompanyData } = useCompanyData();
  const licenseInputRef = useRef<HTMLInputElement>(null);
  //queryTeamId is what was given as queryParam from Slack app. If null we cannot make comparison
  //here is the comparison. If they do not match user has logged in with wrong workspace
  const loggedInWithCorrectWorkSpace = (queryTeamId && queryTeamId === tenant) || !queryTeamId || queryTeamId === "null";
  const [showDialog, setShowDialog] = useState<boolean>(true);
  const [validLicense, setValidLicense] = useState<boolean | undefined | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [attempts, setAttempts] = useState(null);
  const [modal, setModal] = useState<string | null>(null);

  //check if someone with this session has attempted and failed already
  useEffect(() => {
    if (attempts === null) {
      (async () => {
        const tries = await fetch("/attempts");
        const data = await tries.json();
        setAttempts(data.attempts);
      })();
    }
  }, [attempts]);

  async function enterLicense() {
    let licenseKey;
    if (licenseInputRef.current) {
      licenseKey = licenseInputRef.current.value;
    }
    if (!licenseKey || licenseKey.trim() === "") {
      setValidLicense(undefined);
    } else {
      setLoading(true);
      try {
        const [data, statusCode] = await fetchFromBackend("license/" + licenseKey, "PUT");
        if (statusCode === 200) {
          //now get the companyData (which is empty)
          const [data] = await fetchFromBackend("company");
          setCompanyData({ ...data, companyName: "" });
          setValidLicense(true);
          setModal("Company");
        } else {
          setAttempts(data.attempts);
          setValidLicense(false);
          throw new Error("Invalid license code");
        }
        setLoading(false);
      } catch (err) {
        console.log("Couldn't validate license code");
        setLoading(false);
      }
    }
  }
  return (
    <Dialog
      open={showDialog}
      styles={{ maxHeight: "100%" }}
      content={
        <Flex column gap="gap.large" styles={{ marginTop: "1rem" }}>
          {!modal && (
            <>
              {attempts === null && <Loader />}
              {attempts !== null && loggedInWithCorrectWorkSpace && (
                <EnterLicenseCode
                  teamName={teamName}
                  validLicense={validLicense}
                  attempts={attempts}
                  loading={loading}
                  enterLicense={enterLicense}
                  licenseInputRef={licenseInputRef}
                />
              )}
            </>
          )}
          {!loggedInWithCorrectWorkSpace && <InCorrectWorkSpace customLogout={customLogout} />}
          {modal === "Company" && (
            <>
              <Text>
                Your organisation is now ready to use CyberCoach. Before taking it into use we would advice you to fill in some basic information
                about your organisation. You can also do this later at anytime by visiting the configure section in the admin dashboard.
              </Text>
              <Flex
                column
                gap="gap.smaller"
                vAlign="center"
                space="evenly"
                styles={{ width: "100%", height: "80%", marginTop: "0.5em", marginBottom: "0.5em" }}
              >
                {loading ? <Loader /> : <CompanyConfigurations setLoading={setLoading} showModal={setShowDialog} initialConfiguration />}
              </Flex>
            </>
          )}
        </Flex>
      }
      header={<ModalHeader modal={modal} teamName={teamName} />}
    />
  );
}
